import React, { useState, useEffect } from "react"
import Logo from "../../static/img/svg/logo.svg"
import LogoMobile from "../../static/img/svg/logo-mobile.svg"
import { Link } from "gatsby"

const Header = () => {

  // determined if page has scrolled and if the view is on mobile
  const [state, setState] = useState({
    scrolled: false,
    scrolledBanner: false,
    showMenu: false,
  });

  // change state on scroll
  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.pageYOffset > (window.innerHeight * 0.75);
      const isScrolledBanner = window.pageYOffset > window.innerHeight;
      if (isScrolled !== state.scrolled) {
        setState({
          ...state,
          scrolled: !state.scrolled,
        });
      }
      if (isScrolledBanner !== state.scrolledBanner) {
        setState({
          ...state,
          scrolledBanner: !state.scrolledBanner,
        });
      }
    };
    document.addEventListener('scroll', handleScroll);
    return () => {
      // clean up the event handler when the component unmounts
      document.removeEventListener('scroll', handleScroll);
    };
  }, [state, state.scrolled, state.scrolledBanner, state.showMenu]);

  // toggle menu on click
  const toggleMenu = () => {
    setState({
      ...state,
      showMenu: !state.showMenu,
    });
  };

  // Smooth scroll
  if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]', {
      speed: 2000,
      speedAsDuration: true,
      offset: 200
    })
  }

  return (
    <header data-scroll={state.scrolled ? '' : 'top'} data-scrollbanner={state.scrolledBanner ? '' : 'banner-style'}>
      <div className="container">
        <div className={state.showMenu ? 'nav-block open' : 'nav-block'}>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/place/">Place</Link></li>
            <li><Link to="/architecture/">Architecture</Link></li>
            <li><Link to="/info/">Info</Link></li>
          </ul>
        </div>
        <button id="menu-toggle" className={state.showMenu ? 'menu-toggle open' : 'menu-toggle'} onClick={toggleMenu}>
          <span className="icon"></span>
          <span className="menu-toggle-text" id="menu-toggle-text">Menu</span>
        </button>
      </div>
      <div className="container-inner">
        <Link className="logo" to="/">
          <Logo className="desktop" />
          <LogoMobile className="mobile" />
        </Link>
      </div>
    </header>
  )
}

export default Header;
