import React from "react"

export default function Footer() {
  return (

    <footer>
      <div className="container">
        <div className="flex-grid">
          <div>
            <div className="copyright">&copy;2021 Governors Point Land LP</div>
          </div>
          <div>
            <a href="mailto:info@governorspoint.org">info@governorspoint.org</a>
          </div>
        </div>
      </div>
    </footer>

  )
}
